import { Reducer, useReducer } from "react";

type StateReducer = {
	items: string[]
}

type ActionReducer = {
	type: ActionType,
	payload: any
}

enum ActionType {
	SELECTED,
	CLEAR,
}

export default function useSelectableHandler() {

	const reducer = (state: StateReducer, action: ActionReducer) => {
		switch (action.type) {
			case ActionType.SELECTED:
				return {...state, items: [...state.items, action.payload]};
			case ActionType.CLEAR:
				return {...state, items: [] };
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer<Reducer<StateReducer, ActionReducer>>(reducer, {items: []})
	
	const handleSelect = (id: string) => {
		dispatch({ type: ActionType.SELECTED, payload: id });
	}

	const handleClear = () => {
		dispatch({ type: ActionType.CLEAR, payload: ""});
	}

	return {
		items: state,
		handleSelect,
		handleClear
	}
}
