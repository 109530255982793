import { useEffect, useMemo, useState } from "react"

type UsePsQueryOutput<T extends {}> = {
  data?: T,
}

export default function usePsQuery<T extends {}>(repositoryPromise: () => Promise<T>): UsePsQueryOutput<T> {

  const [data, setData] = useState<T>();

  useEffect(() => {
    repositoryPromise().then(setData);
  }, [repositoryPromise]);

  return { 
    data: useMemo(() => data, [data])
   }
}