import axios, { AxiosResponse, Method } from "axios";

import ApiResponse from "./ApiResponse";
import GenericRequest from "./GenericRequest";

export class AxiosGenericRequest<RequestType = object, ResponseType = object>
  implements GenericRequest<ResponseType>
{
  readonly method: Method;

  readonly url: string;

  readonly headers: any;

  readonly data: RequestType;

  readonly params?: any;

  protected constructor(
    method: Method,
    url: string,
    headers: any,
    data: RequestType,
    params?: any
  ) {
    this.method = method;
    this.url = url;
    this.headers = headers;
    this.data = data;
    this.params = params;
    this.parseResponse = this.parseResponse.bind(this);
  }

  middleParser(response: AxiosResponse<ApiResponse<ResponseType>>) {
    let apiResponse;
    if (response.data.data !== undefined) {
      apiResponse = {
        data: response.data.data,
        success: response.data.success,
        errorCode: response.data.errorCode,
        errorMessage: response.data.errorMessage,
      };
    } else if (response.status === 200) {
      apiResponse = {
        data: response.data,
        success: true,
      };
    } else {
      apiResponse = {
        data: null,
        success: false,
        errorCode: response.status,
        errorMessage: response.statusText,
      };
    }

    return apiResponse;
  }

  parseResponse(response: AxiosResponse<ApiResponse<ResponseType>>) {
    const middleResponse = this.middleParser(response);

    return {
      data: middleResponse.data,
      success: middleResponse.success,
      errorCode: middleResponse.errorCode,
      errorMessage: middleResponse.errorMessage,
    } as ApiResponse<ResponseType>;
  }

  request() {
    return axios({
      method: this.method,
      url: this.url,
      headers: this.headers,
      params: this.params,
      data: this.data,
    }).then((response: AxiosResponse<ApiResponse<ResponseType>>) => {
      return this.parseResponse(response);
    });
  }
}
