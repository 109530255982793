import React, { FC } from "react";

import "./Icon.scss";

interface IconProps {
  /**
   * The Google-Fonts icon string
   */
  icon: string;
  /**
   * What custom ID to refer the component
   */
  id?: string;
  /**
   * Does the component wrap children?
   */
  children?: never;
  /**
   * CSS class to custom the component appearance
   */
  className?: string;
  /**
   * Optional click handler
   */
  onClick?: () => any;
}

/**
 * UI icon component
 * Paste the line below in <head> of ./public/index.html in final project
 *<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" type="text/css">
 */
const Icon: FC<IconProps> = ({ id, icon, className, onClick }) => {
  return (
    <span
      id={id}
      className={["material-icons", "icon", className].join(" ")}
      role="button"
      onClick={onClick}
      tabIndex={-1}
      onKeyDown={() => {}}
    >
      {icon}
    </span>
  );
};

export default Icon;
