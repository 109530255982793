import { Method } from "axios";

import { AxiosGenericRequest } from "./AxiosGenericRequest";
import GenericRequest from "./GenericRequest";

export default abstract class BaseApiRequest<Request = {}, Response = {}>
  extends AxiosGenericRequest<Request, Response>
  implements GenericRequest<Response>
{
  protected constructor(
    method: Method,
    url: string,
    data: Request,
    headers?: any,
    params?: any
  ) {
    super(method, url, headers, data, params);
  }
}
