import React, { useEffect, useMemo, useState } from "react";

import Loader from "../../components/Loader";

import AuthContext from "./auth-context";
import Store from "./Store";
import { StoreKeys } from "./StoreKeys";

type AuthProviderProps = {
  store: Store;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children, store }) => {
  const bearerToken: string = "Bearer ";

  const [isInitialDataLoaded, setIsInitialDataLoaded] =
    useState<boolean>(false);

  const [token, setToken] = useState<string | undefined>(undefined);

  const [user, setUser] = useState<string | undefined>(undefined);

  useEffect(() => {
    store
      .get(StoreKeys.AUTH_TOKEN)
      .then((value) => setToken(value as string))
      .finally(() => setIsInitialDataLoaded(true));

    store.get(StoreKeys.AUTH_USER).then((value) => setUser(value as string));
  }, [store]);

  useEffect(() => {
    if (token) {
      store.set(StoreKeys.AUTH_TOKEN, token);
      store.set(StoreKeys.AUTH_USER, user);
    } else {
      store.set(StoreKeys.AUTH_TOKEN, window.sessionStorage.getItem("token"));
      store.set(StoreKeys.AUTH_USER, localStorage.getItem("user"));
    }
  }, [store, token, user]);

  const login = async (token: string, userName: string): Promise<void> => {
    setToken(bearerToken + token);
    setUser(userName);
    localStorage.setItem("user", userName);
    window.sessionStorage.setItem("token", bearerToken + token);
  };

  const logout = async (): Promise<void> => {
    setToken(undefined);
    setUser(undefined);
    localStorage.removeItem("user");
    window.sessionStorage.removeItem("token");
  };

  const authData = useMemo(() => {
    return {
      isAuth: !!token,
      token,
      user,
      login,
      logout,
    };
  }, [token, user, login, logout]);

  if (!isInitialDataLoaded) {
    return <Loader opened={!isInitialDataLoaded} />;
  }

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
