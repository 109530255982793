import React from "react";

type AuthData = {
  isAuth: boolean;
  token?: string;
  refreshToken?: string;
  user?: string;
  login: (token: string, userName: string) => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = React.createContext<AuthData>({
  isAuth: false,
  token: undefined,
  refreshToken: undefined,
  user: undefined,
  login: (token, userName) => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export default AuthContext;
