import localForage from "localforage";

import Store from "./Store";

export default class LocalStore implements Store {
  private static localInstance = new LocalStore();

  public static instance() {
    return LocalStore.localInstance;
  }

  constructor() {
    localForage.config({
      name: "Visor",
      version: 1.0,
      description: "Local store to Visor react app",
    });
  }

  async get(key: string): Promise<object> {
    return localForage.getItem(key) as Promise<object>;
  }

  async set(key: string, value: object): Promise<void> {
    localForage.setItem(key, value);
  }
}
